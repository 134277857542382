$light-grey: #e1e8ed;
$dark-grey: #7d7d7d;
$primary: #59b3ff;
$primary-200: #ebf1f9;
$primary-500: #3a77c3;
$secondary: #50bdb4;
$tertiary: #ffa071;
$black: #292d34;
$white: #ffffff;
$primary-light: #daeeff;
$secondary-light: #d4f5e9;
$tertiary-light: #ffece3;
$neutral-700: #6c6b78;
$neutral-800: #474656;
$neutral-900: #272730;
$error: #cd1818;
$success: #39e75f;

$text-primary: #292d34;
$text-secondary: #adafbb;
$text-grey: rgba(41, 45, 52, 0.7);

$placeholder: rgb(117, 117, 117);
$overlay: rgba(41, 45, 52, 0.4);
$dark-overlay: rgba(41, 45, 52, 0.9);

$button-disabled: rgba(125, 125, 125, 0.1);

$overlay-light: rgba(0, 0, 0, 0.2);
