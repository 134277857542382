@use "styles/breakpoints" as breakpoints;

.main-section {
  padding: 1rem 2rem;
  overflow: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
