@use "styles/colors" as c;

.expandable-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  width: 100%;
  padding: 1rem 1.25rem;
  border: 1px solid c.$light-grey;
  border-radius: 1.6rem;

  .collapsed-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title {
      margin: 0;
    }
  }

  .expandable-content {
    width: 100%;
  }
}
