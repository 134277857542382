@use "styles/breakpoints" as breakpoints;

.view-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  gap: 1rem;
  user-select: none;

  @media (min-width: breakpoints.$lg) {
    justify-content: space-between;
    align-items: center;
  }
}

.text-view {
  max-height: calc(100% - 12rem);

  .view,
  .content {
    height: 100%;
  }
}
