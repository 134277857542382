@use "styles/colors" as c;
@use "styles/breakpoints" as breakpoints;

.summary-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 8px 18px;
  border-radius: 1.6rem;
  border: 1px solid c.$light-grey;

  .title-section {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  .divider {
    height: 1px;
    width: 100%;
    margin-top: 8px;
  }
}
