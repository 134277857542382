@use "styles/breakpoints" as breakpoints;
@use "styles/colors" as c;

.nav-bar {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 1rem 0rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: c.$white;

  @media (min-width: breakpoints.$lg) {
    justify-content: flex-end;
    gap: 0.5rem;
    box-shadow: none;
    padding: 0;
    margin-right: 2rem;
  }
}
