.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    width: 120px;
    height: 120px;
  }
}
