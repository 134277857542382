@use "styles/colors" as c;
@use "styles/breakpoints" as breakpoints;

.summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .title-card {
    text-align: center;
    width: 100%;
    padding: 10px;
    border-radius: 1.6rem;
    background-color: c.$light-grey;
    color: c.$black;
  }

  .programs {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-bottom: 50px;

    @media (min-width: breakpoints.$lg) {
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }
  }

  .continue-btn {
    margin: auto;
    width: 10rem;
  }

  @media (min-width: breakpoints.$lg) {
    margin-left: 10rem;
    margin-right: 10rem;
  }
}
