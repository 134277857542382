@use 'styles/colors' as c;
@use 'styles/breakpoints' as breakpoints;

.avatar,
.initials {
  width: 36px;
  height: 36px;
  border-radius: 50%;

  @media (min-width: breakpoints.$lg) {
    width: 48px;
    height: 48px;
  }
}

.initials {
  background-color: c.$primary-200;
  color: c.$primary-500;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.5px;
  font-size: 1.2rem;
}
