@use "styles/breakpoints" as breakpoints;
@use "styles/colors" as c;

.view {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }

  .video-wrap {
    width: 100%;
    margin-top: 2rem;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .btns {
      display: flex;
      gap: 2rem;
      align-items: center;

      .primary-btn {
        width: 10rem;
      }
    }
  }

  .nav-arrows {
    width: 36px;
    height: 36px;
    cursor: pointer;
    opacity: 0.8;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  .loading {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .animation {
    width: 100%;
    height: 100%;
  }

  @media (min-width: breakpoints.$lg) {
    .nav-arrows {
      width: 48px;
      height: 48px;
    }

    .actions {
      justify-content: center;
    }

    .content {
      justify-content: space-between;
    }

    .video-wrap {
      width: 70%;
      margin-top: 0;
    }
  }

  @media (min-width: breakpoints.$xl) {
    .video-wrap {
      width: 60%;
    }
  }
}
