@use "styles/breakpoints" as breakpoints;

.on-continue-dialog .dialog-container {
  width: 80%;

  .action-button {
    width: 20%;
  }

  @media (min-width: breakpoints.$lg) {
    width: 60%;
  }
}
