@use 'styles/colors' as c;
@use 'styles/breakpoints' as breakpoints;

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 11px;
}

@media (min-width: breakpoints.$md) {
  html {
    font-size: 14px;
  }
}

@media (min-width: breakpoints.$lg) {
  html {
    font-size: 16px;
  }
}

@media (min-width: breakpoints.$xl) {
  html {
    font-size: 18px;
  }
}

.slick-slider .slick-dots {
  bottom: -35px;
  scale: 0.6;

  li {
    button {
      background-color: c.$light-grey;
      border-radius: 50%;
      width: 7px;
      height: 7px;
      margin: 0 auto;
    }

    button::before {
      content: '';
      display: none;
    }

    &.slick-active button {
      width: 20px;
      border-radius: 5px;
      background-color: c.$primary;
    }
  }

  @media (min-width: breakpoints.$sm) {
    scale: 0.8;
  }
}
