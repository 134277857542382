@use "styles/colors" as c;

.test-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  flex: 1;

  .mcq-card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem 1rem;
    border-radius: 1.6rem;
    border: 1px solid c.$light-grey;
    align-self: stretch;
  }

  .mcq {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    &.hidden {
      opacity: 0;
    }
  }
}
