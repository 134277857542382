@use "styles/colors" as c;

.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-weight: 500;
  color: c.$text-primary;
  position: relative;
  overflow: hidden;
  padding: 50px;

  .logo {
    position: absolute;
    top: 4vh;
    left: 2vw;
    width: 7rem;
  }

  .graphic {
    width: 30vw;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: c.$secondary;
    clip-path: polygon(35% 0, 100% 0, 100% 100%, 79% 100%);
    z-index: -1;
  }
}
