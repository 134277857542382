@use "styles/colors" as c;

.summary-item-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;

  .title {
    color: c.$primary;
    margin-bottom: 4px;
  }

  .item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &.with-margin {
      margin-bottom: 14px;
      gap: 10px;
    }

    .action-button {
      padding: 4px 12px;
      margin-left: auto;
    }
  }
}
