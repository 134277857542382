@use "styles/breakpoints" as breakpoints;

.calendar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .calendar-big {
    width: 86px;
    height: auto;
  }

  .calendar-small {
    width: 24px;
    height: auto;
  }

  .lable-small {
    position: absolute;
    top: 11px;
    font-size: 12px;
    line-height: 12px;
  }

  .lable-big {
    position: absolute;
    top: 48%;
    font-size: 24px;
    line-height: 24px;
  }

  @media (min-width: breakpoints.$lg) {

    .calendar-small {
      width: 36px;
    }

    .calendar-big {
      width: 122px;
    }

    .lable-small {
      top: 18px;
      font-size: 16px;
      line-height: 16px;
    }

    .lable-big {
      top: 50%;
      font-size: 28px;
      line-height: 28px;
    }
  }
}
