.report-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .report-title {
    margin: auto;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .greeting-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 6px;
    gap: 5px;
  }

  .initial-description-block {
    margin-top: 6px;
    margin-bottom: 12px;
  }

  .report-blocks {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
    margin-bottom: 12px;
  }

  .final-description-block,
  .disclaimer,
  .warning {
    margin-bottom: 12px;
  }

  .disclaimer-title {
    margin-bottom: 6px;
  }

  .good-luck {
    margin: auto;
    margin-bottom: 20px;
  }

  .continue-btn {
    margin: auto;
    width: 10rem;
  }
}
