@use "styles/colors" as c;
@use "styles/breakpoints" as breakpoints;

.nav-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  text-decoration: none;
  color: c.$text-secondary;
  outline: none;
  cursor: pointer;

  @media screen and (min-width: breakpoints.$lg) {
    padding: 0.5rem 1rem;
    border-radius: 8px;
  }

  svg {
    box-sizing: initial;
    padding: 0.25rem 0.75rem;
  }

  &:hover,
  &.active {
    color: c.$primary-500;

    @media screen and (min-width: breakpoints.$lg) {
      background-color: c.$primary-200;
    }

    svg {
      background-color: c.$primary-200;
      border-radius: 0.5rem;
    }
  }

  @media (min-width: breakpoints.$lg) {
    color: c.$text-primary;

    svg {
      display: none;
    }
  }
}
