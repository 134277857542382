@use "styles/colors" as c;

.mcq-option {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid c.$light-grey;
  cursor: pointer;

  &.selected {
    background-color: lighten(c.$primary, 25%);
  }

  &.incorrect {
    background-color: lighten(c.$error, 25%);
  }

  &.correct {
    background-color: lighten(c.$success, 25%);
  }
}
