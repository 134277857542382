@use 'styles/colors' as c;

.checkbox {
  font-size: 0.8rem;
  color: c.$neutral-800;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  cursor: pointer;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    /* When the checkbox is checked, add a blue background */
    &:checked ~ .checkmark {
      background-color: c.$primary;
      border-color: c.$primary;

      &:after {
        display: block;
      }
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    order: -1;
    position: relative;
    height: 13px;
    width: 13px;
    background-color: c.$white;
    border: 1px solid c.$light-grey;
    border-radius: 2px;

    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 3px;
      top: 0px;
      width: 3px;
      height: 7px;
      border: solid c.$white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
