@use "styles/colors" as c;

h1.text {
  margin: 0;
}

.text {
  font-family: Jost;
  line-height: 2rem;
  font-style: normal;
  color: c.$text-primary;

  &.extra-small {
    font-size: 0.8rem;
  }

  &.small,
  &.small-light,
  &.small-light-italic,
  &.small-dark,
  &.small-primary,
  &.small-secondary,
  &.small-teal {
    font-size: 1rem;
    font-weight: 500;
  }

  &.small-light {
    color: c.$text-grey;
  }

  &.small-light-italic {
    font-style: italic;
    line-height: normal;
    font-weight: 400;
  }

  &.small-dark {
    font-weight: 600;
  }

  &.small-primary {
    color: c.$primary;
  }

  &.small-grey {
    color: c.$text-grey;
    font-weight: 400;
    line-height: normal;
  }

  &.small-secondary {
    color: c.$text-secondary;
    font-weight: 400;
    line-height: normal;
  }

  &.small-teal {
    color: c.$secondary;
    font-weight: 700;
  }

  &.medium,
  &.medium-white,
  &.medium-light,
  &.medium-secondary,
  &.medium-dark {
    font-size: 1.2rem;
    font-weight: 500;
  }

  &.medium-light {
    font-weight: 400;
  }

  &.medium-secondary {
    color: c.$text-secondary;
  }

  &.medium-white {
    color: c.$white;
  }

  &.medium-dark {
    font-weight: 600;
  }

  &.large,
  &.large-light,
  &.large-light-italic {
    font-size: 1.3rem;
    font-weight: 600;
  }

  &.large-light {
    font-weight: 400;
  }

  &.large-light-italic {
    font-style: italic;
    line-height: normal;
    font-weight: 400;
  }

  &.extra-large,
  &.extra-large-light {
    text-align: center;
    line-height: normal;
    font-size: 1.5rem;
    font-weight: 700;
  }

  &.extra-large-light {
    font-weight: 600;
  }
}
