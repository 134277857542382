.mcq {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 0.5rem;

    .option {
      flex: 0 1 48%;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}
