.report-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .report-title {
    margin: auto;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .intro {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  .report-blocks {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
    margin-bottom: 1rem;
  }

  .continue-btn {
    margin: auto;
    width: 10rem;
  }

  .outro {
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.report-not-available {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}