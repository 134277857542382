@use "styles/colors" as c;
@use "styles/breakpoints" as breakpoints;

.download-button {
  border-radius: 8px;
  border: 0;
  padding: 0.3rem 0.8rem;
  color: c.$dark-grey;
  background-color: c.$button-disabled;
  cursor: pointer;
  font-family: Jost;
  font-style: normal;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  .spinner {
    width: 1rem;
  }

  @media (min-width: breakpoints.$lg) {
    padding: 0.5rem 2rem;

    svg {
      scale: 1.5;
    }
  }
}
