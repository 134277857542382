@use 'styles/colors' as c;
@use 'styles/breakpoints' as breakpoints;

.button {
  border-radius: 8px;
  border: 0;
  padding: 0.3rem 0.8rem;
  color: c.$dark-grey;
  background-color: c.$light-grey;
  cursor: pointer;
  font-family: Jost;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;

  &.primary {
    background-color: c.$primary-500;
    color: c.$white;
  }

  &.secondary {
    background-color: c.$secondary;
    color: c.$white;
  }

  &.primary-outline {
    background-color: c.$white;
    border: 1px solid c.$primary-500;
    color: c.$primary-500;
  }

  &.grey-outline {
    background-color: c.$white;
    border: 1px solid c.$dark-grey;
  }

  &.tertiary {
    background-color: c.$tertiary;
    color: c.$white;
  }

  &.locked {
    background-color: c.$button-disabled;
    color: c.$dark-grey;
    font-weight: 500;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .spinner {
    width: 1.5rem;
  }

  @media (min-width: breakpoints.$lg) {
    padding: 0.5rem 2rem;

    svg {
      scale: 1.5;
    }
  }
}
