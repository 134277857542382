@use "styles/colors" as c;

.tnc {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 80%;
  height: 100%;

  .text-wrap {
    max-height: 100%;
    overflow: scroll;
    padding: 1.5rem 1rem;
    border-radius: 1.6rem;
    border: 1px solid c.$light-grey;
  }
}
