@use "styles/colors" as c;

.mood-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
}

.mood-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mood-item img {
    width: 50px;
    height: 50px;
}

.mood-item-lable {
    margin-top: 0.5rem;
    text-align: center;
    color: c.$white;
}