@use 'styles/colors' as c;
@use 'styles/breakpoints' as breakpoints;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid c.$light-grey;

  &.header-with-back-btn {
    justify-content: start;
    padding: 1rem 5rem 1rem 2rem;

    .back-btn {
      cursor: pointer;
    }

    .title {
      flex: 1;
      padding: 0rem 1rem;
    }

    @media (min-width: breakpoints.$lg) {
      .back-btn {
        width: 48px;
        height: 48px;
      }
    }
  }

  .title {
    text-align: center;
  }

  .logo {
    width: 100%;
    height: 100%;
  }

  .profile-link,
  .profile-link-with-margin {
    cursor: pointer;
    text-decoration: none;
  }

  .profile-link-with-margin {
    margin-left: 4rem;
  }

  .logo {
    width: 100%;
    height: 100%;
  }

  .home-link {
    width: 156px;
    height: 24px;
  }
  @media (min-width: breakpoints.$lg) {
    .home-link {
      width: 208px;
      height: 50px;
    }
  }
}
