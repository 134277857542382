@use "styles/colors" as c;

.player-wrap {
  background: url(../../static/images/page_spinner.gif) center center no-repeat;
  width: 100%;
  aspect-ratio: 16/9;
  background-size: 48px 48px;
  position: relative;

  .player,
  .player iframe {
    border-radius: 15px;
    width: 100%;
    aspect-ratio: 16/9;
    border: 0px;
    height: unset;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: c.$dark-overlay;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 2rem;
  }
}