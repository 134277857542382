@use "styles/colors" as c;
@use "styles/breakpoints" as breakpoints;

.divider-horizontal {
  margin-top: 10px;
  width: 100%;
}

.block-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
}

.block-image img {
  width: 100%;
  max-width: 600px;
  height: auto;
}

@media (min-width: 768px) {
  .block-image img {
    max-width: 800px;
  }
}

.chart {
  margin: 2rem auto;
  width: 100%;
  height: 300px;

  @media (min-width: breakpoints.$md) {
    width: 80%;
    height: 400px;
  }
}

.report-block-text {
  margin: 0;

  b {
    color: c.$text-primary;
  }
}

.sections {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .separator {
    width: 100%;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .section-title {
      margin: 0;
    }
  }
}

@media (min-width: breakpoints.$lg) {
  .from-test-completion {
    .sections {
      flex-direction: row;

      .section {
        width: 31%;
      }

      .separator {
        width: unset;
        margin: 0 1rem;
        opacity: 30%;
        color: c.$light-grey;
      }
    }

    .chart {
      width: 50%;
    }
  }
}
