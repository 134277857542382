@use "styles/colors" as c;
@use "styles/breakpoints" as breakpoints;

.dialog-overlay {
  display: flex;
  width: 100%;
  height: 100%;
  background: c.$overlay;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  .dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    background: c.$white;
    width: 80%;
    height: fit-content;
    padding: 2rem;
    justify-content: center;
    cursor: default;
    border-radius: 15px;

    .action-button {
      width: 100%;
    }

    @media (min-width: breakpoints.$lg) {
      width: 30%;
    }
  }
}
