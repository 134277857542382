@use "styles/colors" as c;
@use "styles/breakpoints" as breakpoints;

$dot-size: 2rem;

.timeline {
  display: flex;
  position: relative;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  min-height: $dot-size;
  margin-top: 2rem;
  width: 100%;

  .item {
    list-style-type: none;
    position: relative;
    flex: 1;

    .label {
      display: none;
    }

    /* First item should just be a dot with no preceding line */
    &:first-child {
      flex: 0 0 $dot-size;
    }

    /* Circle for each item */
    .circle {
      position: absolute;
      right: 0;
      width: $dot-size;
      height: $dot-size;
      border-radius: 50%;
      border: 2px solid c.$light-grey;
      background-color: c.$white;
    }

    .check {
      stroke-dasharray: 1000;
      stroke-dashoffset: -100;
    }

    /* Filled line which appears as each item is completed */
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 3px;
      top: 50%;
      transform: translateY(-50%);
      background-color: c.$secondary;
    }

    &.no-animate {
      animation: none;
    }

    /* Animating it when filled class is applied */
    &.checked {
      .circle {
        animation: fill-circle 0.5s forwards 0.5s;
      }
      .check {
        animation: dash-check 0.9s ease-in-out forwards;
      }
      &::before {
        animation: fill-timeline 1s forwards;
      }
    }

    &.unfilled {
      &::before {
        animation: unfill-timeline 1s forwards;
      }
      .circle {
        animation: unfill-circle 0.5s forwards;
      }
    }

    &.filled {
      .circle {
        animation: fill-circle 0.5s forwards 0.5s;
      }
      &::before {
        animation: fill-timeline 1s forwards;
      }
    }
  }

  /* Background grey line of timeline */
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    height: 3px;
    transform: translateY(-50%);
    width: 100%;
    background-color: c.$light-grey;
    z-index: -1;
  }

  .tooltip {
    z-index: 1;
  }

  @media (min-width: breakpoints.$xl) {
    margin: 40px 0;

    .item .label {
      display: block;
      position: absolute;
      right: 0;
      white-space: nowrap;
      top: -40px;
      transform: translateX(calc(50% - 1rem));
    }

    .item:nth-child(even) .label {
      bottom: -40px;
      top: unset;
    }

    .item:first-child .label {
      transform: translateX(calc(100% - $dot-size));
    }

    .item:last-child .label {
      transform: translateX(0%);
    }
  }
}

@keyframes fill-timeline {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes unfill-timeline {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@keyframes fill-circle {
  0% {
    border-color: c.$light-grey;
    background-color: c.$white;
  }
  100% {
    border-color: c.$secondary;
    background-color: c.$secondary;
  }
}

@keyframes unfill-circle {
  0% {
    border-color: c.$secondary;
    background-color: c.$secondary;
  }
  100% {
    border-color: c.$light-grey;
    background-color: c.$white;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
